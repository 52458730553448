import React from 'react'
import { Button, Select } from '@veneer/core'
import { TrailingAreaPropType } from './types'
import useShellRootContext from 'src/contexts/ShellRoot/useShellRootContext'

const TrailingArea = ({
  disableContinue,
  handleSelect,
  onContinue,
  selectOptions,
  selectValue
}: TrailingAreaPropType): JSX.Element => {
  /* istanbul ignore next */
  const { t = (key: string, defaultValue: string) => defaultValue || key } =
    useShellRootContext?.() || {}

  return (
    <>
      <Select
        clearIcon={false}
        data-testid="notifications-list-contextual-footer-select"
        placeholder={t(
          'ecp-notifications-list.selectAnAction',
          'Select an Action'
        )}
        onChange={handleSelect}
        options={selectOptions}
        value={selectValue}
      />
      <Button
        data-testid="notifications-list-contextual-footer-continue-button"
        disabled={disableContinue}
        onClick={onContinue}
        type="button"
      >
        {t('ecp-notifications-list.continue', 'Continue')}
      </Button>
    </>
  )
}

export default TrailingArea
