/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react'
import { Button, IconDownload } from '@veneer/core'
import NotificationsData from './NotificationsData'
import { getMessages } from 'src/services/getMessages'
import FilterNotifications from './FilterNotifications'
import SearchNotification from './SearchNotification'
import FilterSidebar from 'src/shared-Components/FilterSidebar'
import { useShellRootContext } from '../../contexts/ShellRoot'
import {
  ActionArea,
  ActionAreaElements,
  ActionAreaFilterTags,
  Container,
  SearchForm,
  StyledForm,
  StyledTag
} from './styles'
import * as T from './type'

const NotificationsListMainPage = (props: T.NotificationsListType): any => {
  const {
    t = (key: string, defaultValue: string) => defaultValue || key,
    interfaces
  } = useShellRootContext?.() || {}

  const authProvider = interfaces.v2.authProvider
  const authStack = interfaces.v1.app.getAuthStack()
  const events = interfaces.v1.events
  const locale = interfaces.v1.localization.locale

  const [showNotificationFilterSidebar, setShowNotificationFilterSidebar] =
    useState<boolean>(false)
  const [searchNotificationItem, setsearchNotificationItem] =
    useState<string>('')
  const [searchNotificationItems, setsearchNotificationItems] = useState<
    string[]
  >([])
  const [searchNotificationValue, setSearchNotificationValue] =
    useState<string>('')
  const [searchNotificationFlag, setSearchNotificationFlag] =
    useState<boolean>(false)
  const [notifications, setNotifications] = useState<Array<any>>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [hasError, setHasError] = useState<boolean>(false)
  const [showMobileSearchBox, setShowMobileSearchBox] = useState<boolean>(false)

  const tagKey = (subkey: string): string => {
    if (subkey === 'info') {
      return t(`ecp-notifications-list.type`, 'Type') + ' : ' + 'Information'
    }
    if (subkey === 'warning' || subkey === 'success' || subkey === 'error') {
      return (
        t(`ecp-notifications-list.type`, 'Type') +
        ' : ' +
        t(`ecp-notifications-list.${subkey}`, subkey)
      )
    }
    if (
      subkey === 'active' ||
      subkey === 'pending' ||
      subkey === 'resolved' ||
      subkey === 'read' ||
      subkey === 'unread'
    ) {
      return (
        t(`ecp-notifications-list.status`, 'Status') +
        ' : ' +
        t(`ecp-notifications-list.${subkey}`, subkey)
      )
    }
  }

  const getMessagesFromApi = useCallback(async () => {
    const includeDeletedMessages = true
    const items = []
    const limit = 100
    const smart = false

    let offset = 0
    let total = 0

    do {
      try {
        const response = await getMessages({
          authStack,
          authProvider,
          locale: locale,
          offset: offset,
          limit: limit,
          smart: smart,
          includeDeletedMessages: includeDeletedMessages
        })

        items.push(...response.data.items)
        total = response.data.total
        offset += limit
      } catch (e) {
        console.error('Error while fetching messages from API', e)
      }
    } while (items.length < total)

    return items
  }, [authProvider, authStack, locale])

  const getEntireNotificationsList = useCallback(() => {
    return getMessagesFromApi()
  }, [getMessagesFromApi])

  const retriveNotificationsList = useCallback(async () => {
    try {
      setIsLoading(true)
      const entireNotificationsList = await getEntireNotificationsList()
      setNotifications(entireNotificationsList)
      setTimeout(() => setIsLoading(false), 2000)
      setHasError(false)
    } catch (e) {
      setIsLoading(false)
      setHasError(true)
    }
  }, [getEntireNotificationsList])

  useEffect(() => {
    retriveNotificationsList()
  }, [retriveNotificationsList])

  useEffect(() => {
    events.addEventListener('ecp-banner-reload-call', retriveNotificationsList)
    return () => {
      events.removeEventListener(
        'ecp-banner-reload-call',
        retriveNotificationsList
      )
    }
  }, [events, retriveNotificationsList])

  const handlePerformFilter = (value1: string, value2: string) => {
    if (value2 === 'push') {
      setsearchNotificationItems([...searchNotificationItems, value1])
    }
    if (value2 === 'pop') {
      setsearchNotificationItems(
        searchNotificationItems.filter((item) => item !== value1)
      )
    }
    if (value2 === 'clearall') {
      setsearchNotificationItems([])
    }
  }

  // Mock search items for testing
  useEffect(() => {
    if (props.mockNotificationItems) {
      setsearchNotificationItems(props.mockNotificationItems)
    }
  }, [props.mockNotificationItems])

  const handleNotificationFilter = (e: { preventDefault: () => void }) => {
    e.preventDefault()
    setIsLoading(true)
    setsearchNotificationItem(searchNotificationValue)
    setSearchNotificationFlag(!searchNotificationFlag)
    setTimeout(() => {
      setIsLoading(false)
    }, 1000)
  }

  const notificationTableActionArea = () => {
    return (
      <ActionArea data-testid="notifications-component-actionarea">
        <ActionAreaElements>
          <SearchForm data-testid="notification-list-search-form">
            <form
              data-testid="notification-search-form"
              onSubmit={handleNotificationFilter}
            >
              <SearchNotification
                isLoading={isLoading}
                searchNotificationFlag={searchNotificationFlag}
                showMobileSearchBox={showMobileSearchBox}
                showSearchButton={true}
                setSearchNotificationFlag={setSearchNotificationFlag}
                setsearchNotificationItem={setsearchNotificationItem}
                setSearchNotificationValue={setSearchNotificationValue}
                setShowMobileSearchBox={setShowMobileSearchBox}
              />
            </form>

            <FilterNotifications
              isLoading={isLoading}
              searchNotificationItems={searchNotificationItems}
              setShowNotificationFilterSidebar={
                setShowNotificationFilterSidebar
              }
            />
          </SearchForm>

          <Button
            appearance="secondary"
            data-testid="download-button"
            disabled={true}
            leadingIcon={<IconDownload />}
          >
            {t('ecp-notifications-list.exportAll', 'Export All')}
          </Button>
        </ActionAreaElements>

        {searchNotificationItems && (
          <ActionAreaFilterTags>
            {searchNotificationItems.map((item: string, index: number) => (
              <StyledTag
                colorScheme="lavender"
                isTint
                id="notifications-filter-tags"
                key={`${item}-${index}`}
                data-testid={`${item}-${index}-tag`}
                label={tagKey(item)}
                clearButton
                onClick={() => {
                  searchNotificationItems.splice(index, 1)
                  retriveNotificationsList()
                }}
              />
            ))}

            {searchNotificationItems.length > 1 && (
              <Button
                id="clear-all-tags"
                data-testid="clear-all-tags"
                appearance="ghost"
                small
                onClick={() => {
                  searchNotificationItems.splice(
                    0,
                    searchNotificationItems.length
                  )
                  retriveNotificationsList()
                }}
              >
                {t('ecp-notifications-list.clearAll', 'Clear All')}
              </Button>
            )}
          </ActionAreaFilterTags>
        )}

        {showMobileSearchBox && (
          <StyledForm
            data-testid="notification-search-form-mobile"
            onSubmit={handleNotificationFilter}
          >
            <SearchNotification
              isLoading={isLoading}
              searchNotificationFlag={searchNotificationFlag}
              showMobileSearchBox={showMobileSearchBox}
              showSearchButton={false}
              setSearchNotificationFlag={setSearchNotificationFlag}
              setsearchNotificationItem={setsearchNotificationItem}
              setSearchNotificationValue={setSearchNotificationValue}
              setShowMobileSearchBox={setShowMobileSearchBox}
            />
          </StyledForm>
        )}
      </ActionArea>
    )
  }

  return (
    <Container data-testid="notifications-list">
      <NotificationsData
        data-testid="notifications-list-component"
        actionArea={notificationTableActionArea()}
        hasError={hasError}
        notifications={notifications}
        isLoading={isLoading}
        searchNotificationItem={searchNotificationItem}
        searchNotificationItems={searchNotificationItems}
        retriveNotifications={retriveNotificationsList}
      />

      <FilterSidebar
        data-testid="notification-filter-side-bar"
        onClose={() => setShowNotificationFilterSidebar(false)}
        performFilter={(value1: string, value2: string) =>
          handlePerformFilter(value1, value2)
        }
        appliedFilter={searchNotificationItems}
        show={showNotificationFilterSidebar}
      />
    </Container>
  )
}

export default NotificationsListMainPage
