import styled from 'styled-components'
import primitives from '@veneer/primitives'
import { Select, Tag } from '@veneer/core'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: ${primitives.layout.size6}px;
  button[aria-label='Column Options'] {
    margin-left: 0 !important;
    align-items: baseline;
    background: none;
  }
  .notification-table-br li[role='menuitem'] {
    margin: 0;
    :not(:last-of-type) {
      margin-bottom: 4px;
    }
  }
  .notification-table-br ul {
    padding: calc(7px);
  }
  .notification-table-br>div: first-child {
    height: unset;
  }
`

export const ActionSelect = styled(Select)`
  && {
    min-width: 224px;
    background-color: white;
    border-radius: 20px;
    @media screen and (max-width: ${primitives.layout.breakpoint1}px) {
      min-width: initial;
      width: 100%;
    }
  }
`

export const ColumnPiece = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: 20px;
  align-items: center;
  white-space: nowrap;
`

export const ContextualMenuArea = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  top: 0;
  left: 0;
`

export const UserInfo = styled.div`
  position: relative;
  z-index: 2;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const ReorderColumnPortal = styled.div`
  .vn-modal--footer div {
    flex-direction: row-reverse;
    gap: 12px;
  }
`
export const Gap4px = styled.div`
  width: 4px;
  height: auto;
`

export const ReadIcon = styled.div`
  background: #be1313;
  border-radius: 100%;
  height: 4px;
  radius: 4px;
  width: 4px;
`

export const MessageBody = styled.div`
  max-width: 295px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
`
export const TypeContainer = styled.div`
  align-items: center;
  display: flex;
`
export const StyledStatus = styled.label`
  display: flex;
  align-items: center;
  gap: 5px;
`
export const ActionArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: ${primitives.layout.breakpoint1}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`
export const ActionAreaElements = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`
export const ActionAreaFilterTags = styled.div`
  display: flex;
  width: 100%;
`
export const SearchForm = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  flex-grow: 1;

  div.vn-search {
    min-width: 272px;
  }

  && {
    button {
      min-width: 0;
      margin-right: 0;
    }
    .notificationsDisableHoverBackground:hover {
      background-color: transparent;
    }
  }
`
export const StyledTag = styled(Tag)`
  gap: 8px;
  background-color: rgba(2, 120, 171, 0.1) !important;
  display: flex;
  padding: 4px 12px;
  height: 28px !important;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin: 5px 5px 5px 0;

  > span {
    font-size: 16px;
    color: #014667;
    overflow: inherit;
    text-overflow: inherit;
  }

  svg {
    fill: #014667;
    width: 16px;
    height: 16px;
    max-width: 16px;
    max-height: 16px;
  }

  [role='button']:hover {
    background-color: transparent;
  }
`
export const StyledForm = styled.form`
  width: 100%;
`
