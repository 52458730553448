/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react'
import ContextualFooter from 'src/shared-Components/ContextualFooter'
import useShellRootContext from 'src/contexts/ShellRoot/useShellRootContext'

interface ContextualFooterProps {
  numberOfSelectedItems: number
  onCancel: () => void
  onMarkRead: () => void
  onRemove: () => void
}

interface SelectOption {
  value: string | number
}

const NotificationContextualFooter = ({
  numberOfSelectedItems,
  onCancel,
  onMarkRead,
  onRemove
}: ContextualFooterProps): React.ReactElement => {
  /* istanbul ignore next */
  const { t = (key: string, defaultValue: string) => defaultValue || key } =
    useShellRootContext?.() || {}

  const [disableContinue, setDisableContinue] = useState<boolean>(true)
  const [selectValues, setSelectValues] = useState<Array<string>>([])

  useEffect(() => {
    selectValues.length === 0
      ? setDisableContinue(true)
      : setDisableContinue(false)
  }, [selectValues, numberOfSelectedItems])

  const getContextualFooterOptions = () => {
    return [
      {
        label: t('ecp-notifications-list.markRead', 'Mark as Read'),
        value: 'markRead'
      },
      { label: t('ecp-notifications-list.remove', 'Remove'), value: 'remove' }
    ]
  }

  /* istanbul ignore next */
  const onContinue = useCallback(() => {
    if (selectValues[0] === 'markRead') {
      onMarkRead()
      onCancel()
    } else {
      onRemove()
    }
  }, [onCancel, onMarkRead, onRemove, selectValues])

  return (
    <ContextualFooter
      data-testid="contextual-footer"
      disableContinue={disableContinue}
      handleSelect={({ value: v }: SelectOption) => {
        setSelectValues([v.toString()])
      }}
      numberOfSelectedItems={numberOfSelectedItems}
      onCancel={() => onCancel()}
      onContinue={() => onContinue()}
      selectOptions={getContextualFooterOptions()}
      selectValue={selectValues}
    />
  )
}

export default NotificationContextualFooter
