import React, { ReactElement } from 'react'
import { HeaderBanner } from '../../shared-Components/Header'
import PageBody from '../NotificationsPageBody/index'
import useShellRootContext from '../../contexts/ShellRoot/useShellRootContext'
import * as S from './styles'

const NotificationsPage = (): ReactElement => {
  /* istanbul ignore next */
  const { t = (key: string, defaultValue: string) => defaultValue || key } =
    useShellRootContext?.() || {}

  return (
    <>
      <HeaderBanner
        title={`${t('ecp-notifications-list.title', 'Notifications')}`}
      />

      <S.Container>
        <PageBody />
      </S.Container>
    </>
  )
}

export default NotificationsPage
