import { TablePreferences } from '@veneer/core'

export const defaultpageSizeOptions = [
  { value: 5 },
  { value: 25 },
  { value: 50 },
  { value: 100 },
  { value: 500 }
]

export const defaultOrderColumn = [
  'typeElement',
  'messageElement',
  'statusElement',
  'updated',
  'modelName',
  'serialNumber',
  'group',
  'deviceLocation'
]

export const preferences: TablePreferences = {
  width: [
    {
      columnId: 'typeElement',
      width: 144
    },
    {
      columnId: 'messageElement',
      width: 341
    },
    {
      columnId: 'statusElement',
      width: 144
    },
    {
      columnId: 'updated',
      width: 184
    },
    {
      columnId: 'modelName',
      width: 341
    },
    {
      columnId: 'serialNumber',
      width: 144
    },
    {
      columnId: 'group',
      width: 144
    },
    {
      columnId: 'deviceLocation',
      width: 152
    }
  ]
}

export const NotificationStatus = [
  { value: 'Active', label: 'statusActive' },
  { value: 'Pending', label: 'statusPending' },
  { value: 'Resolved', label: 'statusResolved' },
  { value: 'N/A', label: 'statusNotApplicable' }
]

export const NotificationStatusValues = NotificationStatus.map((item) =>
  item.value.toLowerCase()
)
