/* eslint-disable @typescript-eslint/no-explicit-any */
import { Statuses, Types } from 'src/shared-Components/FilterSidebar/constants'

export const TypeValues = Types.map((item) => item.value.toLowerCase())
export const StatusValues = Statuses.map((item) => item.value.toLowerCase())

export const getUpdatedAt = (receivedAt: number, deletedAt: number): string => {
  const receivedDate = new Date(receivedAt)
  const deletedDate = new Date(deletedAt)
  const date = new Date(Math.max(receivedDate.getTime(), deletedDate.getTime()))
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true
  }
  const formattedDate = date.toLocaleString('en-US', options)
  return formattedDate
}

export const getMessageStatus = (
  receivedAt: Date,
  messageLevel: string,
  dismissedAt: Date | null
): any => {
  const oneWeekAgo = new Date()
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7)
  const receivedDate = new Date(receivedAt)
  if (messageLevel === 'warning' || messageLevel === 'error') {
    if (dismissedAt) {
      return 'Resolved'
    } else if (receivedDate >= oneWeekAgo) {
      return 'Active'
    } else {
      return 'Pending'
    }
  } else {
    return 'N/A'
  }
}

export const filter = (
  notificationListData: Array<any>,
  searchItem: string,
  searchItems: string[]
): any => {
  if (searchItem.length === 0 && searchItems.length === 0) {
    return notificationListData
  }

  let searchItemString = searchItem
  const INFORMATION = 'information'

  const filterdata = notificationListData
    .filter((notification) => {
      const formattedReceivedAt = getUpdatedAt(
        notification.receivedAt,
        notification.deletedAt
      )
      const messageStatus = getMessageStatus(
        notification.receivedAt,
        notification.messageLevel,
        notification.dismissedAtDate
      )
      if (INFORMATION.search(searchItemString.toLowerCase()) !== -1) {
        searchItemString = 'info'
      }
      if (searchItem.length != 0) {
        return (
          notification?.messageLevel
            ?.toLowerCase()
            .includes(searchItemString.toLowerCase()) ||
          notification?.title
            ?.toLowerCase()
            .includes(searchItem.toLowerCase()) ||
          notification?.body
            ?.toLowerCase()
            .includes(searchItem.toLowerCase()) ||
          formattedReceivedAt
            .toLowerCase()
            .includes(searchItem.toLowerCase()) ||
          notification?.fields?.group
            ?.toLowerCase()
            .includes(searchItem.toLowerCase()) ||
          notification?.fields?.deviceLocation
            ?.toLowerCase()
            .includes(searchItem.toLowerCase()) ||
          notification?.fields?.model_name
            ?.toLowerCase()
            .includes(searchItem.toLowerCase()) ||
          notification?.fields?.deviceModel
            ?.toLowerCase()
            .includes(searchItem.toLowerCase()) ||
          notification.fields.serial_number
            ?.toLowerCase()
            .includes(searchItem.toLowerCase()) ||
          notification.fields.serialNumber
            ?.toLowerCase()
            .includes(searchItem.toLowerCase()) ||
          messageStatus.toLowerCase().includes(searchItem.toLowerCase())
        )
      } else {
        return notification
      }
    })
    .filter((notification) => {
      if (
        searchItems.length != 0 &&
        (searchItems.includes('info') ||
          searchItems.includes('warning') ||
          searchItems.includes('error') ||
          searchItems.includes('success'))
      ) {
        if (searchItems.some((item) => TypeValues.includes(item))) {
          return searchItems.some(
            (value) => notification?.messageLevel?.toLowerCase() === value
          )
        }
      } else {
        return notification
      }
    })
    .filter((notification) => {
      if (
        searchItems.length != 0 &&
        (searchItems.includes('active') ||
          searchItems.includes('pending') ||
          searchItems.includes('resolved'))
      ) {
        if (searchItems.some((item) => StatusValues.includes(item))) {
          return searchItems.some(
            (value) => notification?.status?.toLowerCase() === value
          )
        }
      } else {
        return notification
      }
    })
    .filter((notification) => {
      if (
        searchItems.length != 0 &&
        (searchItems.includes('read') || searchItems.includes('unread'))
      ) {
        if (searchItems.some((item) => StatusValues.includes(item))) {
          return searchItems.some(
            (value) => notification?.read?.toLowerCase() === value
          )
        }
      } else {
        return notification
      }
    })

  return filterdata
}

export const selectRow = (
  dataArray: any[],
  checked: boolean,
  id?: number
): any => {
  const updatedDataArray = [...dataArray]
  const index = updatedDataArray.findIndex((row) => row.id === id)
  updatedDataArray[index].rowConfig.selected = checked
  return updatedDataArray
}

export const handleMultipleNotifications = (
  dataArray: any[],
  checked: boolean
): any => {
  return dataArray.map((row) => {
    const newRow = row
    newRow.rowConfig.selected = checked
    return newRow
  })
}

export const selectionState = (dataArray: any[]): any => {
  const total = dataArray.length
  const selected = dataArray.filter((row) => row.rowConfig.selected).length

  switch (selected) {
    case 0:
      return 'none'
    case total:
      return 'all'
    default:
      return 'indeterminated'
  }
}

export const sortTable = (
  object1: Record<string, any>,
  object2: Record<string, any>,
  id: string,
  value: number
): number => {
  let fieldA = object1[id]
  let fieldB = object2[id]

  if (typeof fieldA === 'string' && typeof fieldB === 'string') {
    if (!isNaN(Date.parse(fieldA)) && !isNaN(Date.parse(fieldB))) {
      fieldA = new Date(fieldA)
      fieldB = new Date(fieldB)
    } else {
      fieldA = fieldA.toLowerCase()
      fieldB = fieldB.toLowerCase()
    }
  } else if (
    (fieldA === null || fieldB === null) &&
    typeof fieldA !== typeof fieldB
  ) {
    if (fieldA === null) return -value
    if (fieldB === null) return value
  }
  if (!isNaN(fieldA) && !isNaN(fieldB)) {
    fieldA = Number(fieldA)
    fieldB = Number(fieldB)
  }

  if (fieldB < fieldA) {
    return -value
  }
  if (fieldB > fieldA) {
    return value
  }
  return 0
}

export const jsonParse = (text: string, defResult: any[] = []): any => {
  try {
    return text ? JSON.parse(text) : defResult
  } catch (SyntaxError) {
    return defResult
  }
}
