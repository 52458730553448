/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { JarvisWebHttpClient } from '@jarvis/web-http'
import { Stack } from '@jarvis/web-stratus-client'

const getStackPrefix = (authStack) => {
  const urlEnv = (() => {
    switch (authStack) {
      case Stack.prod:
        return ''
      case Stack.stage:
        return 'stage-'
      case Stack.pie:
        return 'pie-'
      default:
        return 'dev-'
    }
  })()

  return `https://${urlEnv}us1.api.ws-hp.com/activitylog/v2`
}

export const getMessages = async ({
  authStack,
  authProvider,
  locale,
  offset,
  limit,
  smart,
  includeDeletedMessages
}) => {
  const url = `${getStackPrefix(authStack)}/messages`
  const client = new JarvisWebHttpClient({ defaultAuthProvider: authProvider })

  const response = await client.get({
    params: {
      locale,
      offset,
      limit,
      smart,
      includeDeletedMessages
    },
    url
  })

  return response
}
