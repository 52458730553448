import styled from 'styled-components'
import tokens from '@veneer/tokens'
import { Button } from '@veneer/core'

export const FilterSidebarContainer = styled.div`
  div[aria-label='Backdrop'] {
    backdrop-filter: none !important;
    background-color: transparent !important;
  }
  div[id='filter-sidebar'] {
    display: flex;
    flex-direction: column;
    padding: ${tokens.layout.size6};
  }
  #filter-sidebar-container {
    overflow: auto;
    padding: ${tokens.layout.size6};
  }
`

export const FilterSidebarPrimaryTitle = styled.p`
  font-size: ${tokens.typography.size5};
  line-height: ${tokens.typography.lineHeight5};
  margin-bottom: ${tokens.layout.size2};
  text-align: left;
`

export const FilterSidebarPrimaryDesc = styled.p`
  font-size: ${tokens.typography.size2};
  line-height: ${tokens.typography.lineHeight3};
  margin-bottom: ${tokens.layout.size4};
  text-align: left;
`

export const SearchFiltersDiv = styled.form`
  margin-bottom: ${tokens.layout.size8};
`

export const CreateFiltersButton = styled(Button)`
  width: ${tokens.typography.lineHeight6};
  height: ${tokens.typography.lineHeight6};
  border-radius: ${tokens.layout.cornerRadius2} !important;
  float: right;
`

export const FIlterContainer = styled.div`
  #favouriteList,
  #connectivityList,
  #customList {
    ul {
      li {
        border: 0;
        height: ${tokens.typography.lineHeight7};
        padding: 0 ${tokens.layout.size2};
        span {
          font-size: ${tokens.typography.size2};
          line-height: ${tokens.typography.lineHeight2};
        }
        button {
          padding: 0;
        }
        button:first-child {
          margin-right: ${tokens.layout.size3};
        }
      }
      li:hover {
        background-color: #f4f4f4;
      }
    }
  }

  #connectivityList,
  #customList {
    ul {
      li {
        .hidden-child {
          visibility: hidden;
        }
      }
      li:hover {
        .hidden-child {
          visibility: visible;
        }
      }
    }
  }

  ul[role='listbox'] {
    li:hover {
      background-color: #035c84 !important;
    }
  }

  span {
    max-width: none;
  }
`

export const FilterList = styled.div`
  margin-bottom: 10px;

  li {
    border: none;
  }
`

export const FilterLabel = styled.p`
  color: ${tokens.color.highContrastGray};
  float: left;
  font-size: ${tokens.typography.size2};
  line-height: ${tokens.layout.size5};
  text-align: left;
  width: 32px;
`

export const Divider = styled.hr<{ width: string }>`
  float: right;
  width: ${(props) => props.width};
  border-top: 1px solid #2121211a;
  margin: 10px 0;
`

export const MarginBottom60 = styled.div`
  width: 100%;
  margin-bottom: 60px;
`

export const PrimaryFooter = styled.div`
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 49px;
  display: flex;
  background-color: white;
  border-top: 1px solid rgba(33, 33, 33, 0.1);
`

export const PrimaryFooterContainer = styled.div`
  position: relative;
  width: 100%;
  padding: 0 24px 0 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const SelectedItemsText = styled.p`
  font-size: ${tokens.typography.size2};
  line-height: ${tokens.typography.lineHeight3};
  text-align: left;
`

export const ClearAllButton = styled(Button)`
  max-width: 83px;
  min-width: 83px;
  height: ${tokens.typography.lineHeight6};
  &:hover {
    background-color: transparent !important;
  }
`
