/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import { Button, IconFunnel } from '@veneer/core'
import { FilterNotificationsProps } from './type'
import { useShellRootContext } from '../../contexts/ShellRoot'

const FilterNotifications = ({
  isLoading,
  searchNotificationItems,
  setShowNotificationFilterSidebar
}: FilterNotificationsProps): any => {
  /* istanbul ignore next */
  const { t = (key: string, defaultValue: string) => defaultValue || key } =
    useShellRootContext?.() || {}

  const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState(window.innerWidth)
    const callback = () => setWindowSize(window.innerWidth)

    useEffect(() => {
      window.addEventListener('resize', callback)
      return () => window.removeEventListener('resize', callback)
    }, [])

    return windowSize
  }

  const resolution = useWindowSize()
  const isMobileView = resolution <= 600

  const filterButton = (isMobileView?: boolean) => {
    return (
      <Button
        appearance="ghost"
        data-testid="notifications-table-filter-button"
        className="notificationsDisableHoverBackground"
        disabled={isLoading}
        leadingIcon={
          <IconFunnel
            data-testid="icon-funnel"
            filled={searchNotificationItems.length > 0 ? true : false}
          />
        }
        onClick={() => setShowNotificationFilterSidebar(true)}
      >
        {isMobileView ? '' : t('ecp-notifications-list.filter', 'Filter')}
        {searchNotificationItems.length > 0
          ? ` (` + searchNotificationItems.length + `)`
          : ''}
      </Button>
    )
  }

  return (
    <>
      {isMobileView && filterButton(isMobileView)}
      {!isMobileView && filterButton()}
    </>
  )
}

export default FilterNotifications
