import React from 'react'
import LeadingArea from 'src/shared-Components/ContextualFooter/LeadingArea'
import TrailingArea from 'src/shared-Components/ContextualFooter/TrailingArea'
import {
  NotificationContextualmenu,
  NotificationFooterContent,
  NotificationLeadingContent,
  NotificationTrailingContent
} from './styles'
import { ContextualFooterPropTypes } from './types'

const ContextualFooter = ({
  disableContinue,
  handleSelect,
  numberOfSelectedItems,
  onCancel,
  onContinue,
  selectOptions,
  selectValue
}: ContextualFooterPropTypes): JSX.Element => {
  return (
    <NotificationContextualmenu data-testid="notification-footer-contextual-menu">
      <NotificationFooterContent data-testid="notifications-list-footer-content">
        {/* LeadingArea content */}
        <NotificationLeadingContent data-testid="notifications-list-leading-area">
          <LeadingArea
            numberOfSelectedItems={numberOfSelectedItems}
            onCancel={onCancel}
          />
        </NotificationLeadingContent>

        {/* TrailingArea content */}
        <NotificationTrailingContent data-testid="notifications-list-trailing-area">
          <TrailingArea
            disableContinue={disableContinue}
            handleSelect={handleSelect}
            onContinue={onContinue}
            selectOptions={selectOptions}
            selectValue={selectValue}
          />
        </NotificationTrailingContent>
      </NotificationFooterContent>
    </NotificationContextualmenu>
  )
}

export default ContextualFooter
