import React, { useEffect, useState } from 'react'
import { SideBar, Checkbox, List, ListItem, IconX, Search } from '@veneer/core'
import { Statuses, Types } from 'src/shared-Components/FilterSidebar/constants'
import {
  FilterLabel,
  Divider,
  MarginBottom60,
  PrimaryFooter,
  SelectedItemsText,
  ClearAllButton,
  PrimaryFooterContainer,
  FilterSidebarPrimaryTitle,
  FilterSidebarPrimaryDesc,
  SearchFiltersDiv,
  FIlterContainer,
  FilterList,
  FilterSidebarContainer
} from 'src/shared-Components/FilterSidebar/styles'
import { FilterSidebarPropTypes } from 'src/shared-Components/FilterSidebar/types'
import useShellRootContext from 'src/contexts/ShellRoot/useShellRootContext'

const FilterSidebar = ({
  appliedFilter,
  onClose,
  performFilter,
  show
}: FilterSidebarPropTypes): JSX.Element => {
  const { t = (key: string, defaultValue: string) => defaultValue || key } =
    useShellRootContext?.() || {}

  const [searchFilterValue, setSearchFilterValue] = useState<string>('')
  const [showInvalidSearchMsg, setShowInvalidSearchMsg] =
    useState<boolean>(false)
  const [statusLabels, setStatusLabels] = useState([])
  const [typeLabels, setTypeLabels] = useState([])

  useEffect(() => {
    setStatusLabels(Statuses)
    setTypeLabels(Types)
  }, [])

  const handleSearchFilterValue = (value: string) => {
    setSearchFilterValue(value)
  }

  const handleClearSearch = () => {
    setSearchFilterValue('')
    setStatusLabels(Statuses)
    setTypeLabels(Types)
    setShowInvalidSearchMsg(false)
  }

  const handleFilterSearch = (e: { preventDefault: () => void }) => {
    e.preventDefault()

    const typeSearch = Types.filter((type) =>
      type.value.includes(searchFilterValue)
    )

    const statusSearch = Statuses.filter((status) =>
      status.value.includes(searchFilterValue)
    )

    if (searchFilterValue.length) {
      if (typeSearch.length) {
        setTypeLabels(typeSearch)
        setStatusLabels([])
        setShowInvalidSearchMsg(false)
      } else if (statusSearch.length) {
        setStatusLabels(statusSearch)
        setTypeLabels([])
        setShowInvalidSearchMsg(false)
      } else {
        setShowInvalidSearchMsg(true)
      }
    } else {
      setStatusLabels(Statuses)
      setTypeLabels(Types)
      setShowInvalidSearchMsg(false)
    }
  }

  const handleFilterCheck = (e: {
    target: { checked: boolean; value: string }
  }) => {
    e.target.checked
      ? performFilter(e.target.value, 'push')
      : performFilter(e.target.value, 'pop')
  }

  const handleCheckboxChange = (e: {
    target: EventTarget & HTMLInputElement
  }) => {
    handleFilterCheck(e)
    fillOrRemoveFromArr(e.target)
  }

  const [arr, setArr] = useState<Array<EventTarget & HTMLInputElement>>([])

  const fillOrRemoveFromArr = (target: EventTarget & HTMLInputElement) => {
    target.checked
      ? setArr([...arr, target])
      : setArr(arr.filter((item) => item !== target))
  }

  const handleAllUncheck = () => {
    for (const value of arr) {
      value.checked = false
      setArr([])
    }
  }

  const isChecked = (type: { value: string; label: string }): boolean => {
    if (appliedFilter.includes(type.value)) {
      return true
    } else if (arr.includes(arr.filter((item) => item.id === type.label)[0])) {
      return true
    } else {
      return false
    }
  }

  const mainContent = (
    <>
      <FilterSidebarPrimaryTitle data-testid="notificattions-list-filter-sidebar-primary-title">
        {t('ecp-notifications-list.filter', 'Filter')}
      </FilterSidebarPrimaryTitle>

      <FilterSidebarPrimaryDesc data-testid="notificattions-list-filter-sidebar-description">
        {t(
          'ecp-notifications-list.selectFiltersToView',
          'Select filters to view in the list.'
        )}
      </FilterSidebarPrimaryDesc>

      <SearchFiltersDiv
        data-testid="notifications-list-search-filters-div"
        id="search-filters-div"
        onSubmit={handleFilterSearch}
      >
        <Search
          data-testid="notifications-list-search-filters-box"
          id="search-filters-box"
          className="marginR11 floatL"
          placeholder={`${t('ecp-notifications-list.searchFilters', 'Search Filters')}`}
          onChange={handleSearchFilterValue}
          onClear={handleClearSearch}
          content={undefined}
          nonce={undefined}
          rel={undefined}
          rev={undefined}
        />
      </SearchFiltersDiv>

      {!showInvalidSearchMsg && (
        <FIlterContainer>
          {typeLabels.length ? (
            <FilterList id="typeFilterList">
              <FilterLabel>
                {t('ecp-notifications-list.type', 'Type')}
              </FilterLabel>
              <Divider width={'260px'} />

              <List>
                {typeLabels.map((type) => (
                  <ListItem
                    key={`list-item-${type.value}`}
                    data-testid={`notifications-list-filter-type-${type.value}`}
                    id="filter-block-2-item-1"
                    leadingArea={
                      <Checkbox
                        className="lablesCheckBox"
                        checked={isChecked(type)}
                        data-testid="filter-block-2-checkbox-1"
                        id={`list-item-${type.value}`}
                        label={type.label}
                        name={`checkbox-${type.value}`}
                        onChange={handleCheckboxChange}
                        value={type.value}
                      />
                    }
                  />
                ))}
              </List>
            </FilterList>
          ) : (
            <></>
          )}

          {statusLabels.length ? (
            <FilterList id="statusFilterList">
              <FilterLabel>
                {t('ecp-notifications-list.status', 'Status')}
              </FilterLabel>
              <Divider width={'245px'} />

              <List>
                {statusLabels.map((status) => (
                  <ListItem
                    key={`list-item-${status.value}`}
                    data-testid={`notifications-list-filter-status-${status.value}`}
                    id="filter-block-2-item-1"
                    leadingArea={
                      <Checkbox
                        className="lablesCheckBox"
                        checked={isChecked(status)}
                        data-testid="notifications-list-filter-block-2-checkbox-1"
                        id={`list-item-${status.value}`}
                        label={status.label}
                        name={`checkbox-${status.value}`}
                        onChange={handleCheckboxChange}
                        value={status.value}
                      />
                    }
                  />
                ))}
              </List>
            </FilterList>
          ) : (
            <></>
          )}

          <MarginBottom60 />
        </FIlterContainer>
      )}

      {showInvalidSearchMsg && (
        <div
          data-testid="notifications-list-filter-sidebar-not-available-span"
          className="not-available-span"
        >
          {t(
            'ecp-notifications-list.searchedFilterIsNotAvailable',
            'Searched filter is not available'
          )}
        </div>
      )}

      {appliedFilter.length ? (
        <PrimaryFooter data-testid="notifications-list-filter-sidebar-primary-footer">
          <PrimaryFooterContainer>
            <SelectedItemsText data-testid="notifications-list-filtersidebar-selected-items-text">
              {appliedFilter.length}
              {` `}
              {t('ecp-notifications-list.itemsSelected', 'items selected')}
            </SelectedItemsText>
            <ClearAllButton
              data-testid="notifications-list-filter-sidebar-clearall-button"
              appearance="ghost"
              leadingIcon={<IconX />}
              onClick={() => {
                handleAllUncheck()
                performFilter('', 'clearall')
              }}
            >
              {t('ecp-notifications-list.clearAll', 'Clear All')}
            </ClearAllButton>
          </PrimaryFooterContainer>
        </PrimaryFooter>
      ) : null}
    </>
  )

  const getContent = () => {
    return <>{mainContent}</>
  }

  return (
    <FilterSidebarContainer>
      <SideBar
        behavior="overlay"
        content={getContent()}
        data-testid="notifications-list-filter-sidebar-container"
        disableBackdropClick
        id="filter-sidebar-container"
        onClose={() => onClose()}
        position="end"
        show={show}
        size={302}
      />
    </FilterSidebarContainer>
  )
}

export default FilterSidebar
