import React from 'react'
import { Button } from '@veneer/core'
import { LeadingAreaText } from 'src/shared-Components/ContextualFooter/styles'
import { LeadingAreaPropType } from 'src/shared-Components/ContextualFooter/types'
import useShellRootContext from 'src/contexts/ShellRoot/useShellRootContext'

const LeadingArea = ({
  numberOfSelectedItems,
  onCancel
}: LeadingAreaPropType): JSX.Element => {
  /* istanbul ignore next */
  const { t = (key: string, defaultValue: string) => defaultValue || key } =
    useShellRootContext?.() || {}

  return (
    <>
      <Button
        appearance="secondary"
        onClick={onCancel}
        small
        data-testid="footer-cancel-button"
      >
        {t('ecp-notifications-list.cancel', 'Cancel')}
      </Button>
      <LeadingAreaText>{`${numberOfSelectedItems} ${t('ecp-notifications-list.itemsSelected', 'items selected')}`}</LeadingAreaText>
    </>
  )
}

export default LeadingArea
