/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useCallback,
  useEffect,
  useState,
  UIEvent,
  useMemo
} from 'react'
import {
  TableSortBy,
  SortTypes,
  PageSizeInterface,
  Table,
  Tooltip,
  IconWarningAlt,
  IconXCircle,
  IconInfo,
  IconCheckmarkCircle,
  IconCircle
} from '@veneer/core'
import ContextualFooter from './contextualFooter'
import { useShellRootContext } from 'src/contexts/ShellRoot'
import {
  selectRow,
  filter,
  handleMultipleNotifications,
  selectionState,
  jsonParse,
  sortTable,
  getUpdatedAt,
  getMessageStatus
} from '../../utils/commonMethods'
import {
  defaultOrderColumn,
  defaultpageSizeOptions,
  preferences
} from '../../utils/constants'
import {
  ColumnPiece,
  Gap4px,
  MessageBody,
  ReadIcon,
  TypeContainer,
  StyledStatus
} from './styles'

const defSortOrder: SortTypes = 'descending'

const defSorting: TableSortBy = {
  id: 'updated',
  type: defSortOrder
}

const NotificationsData = ({
  actionArea,
  notifications,
  searchNotificationItem,
  searchNotificationItems,
  isLoading,
  hasError
}: any): any => {
  /* istanbul ignore next */
  const { t = (key: string, defaultValue: string) => defaultValue || key } =
    useShellRootContext?.() || {}

  const notificationsTableColumns = 'notificationsListMFEColumns'

  type ColumnIndexTypes = 'visible' | 'hidden' | 'none'

  type notificationListColumnstype = {
    id: string
    label: string
    index?: ColumnIndexTypes
    required?: boolean
    sortable?: boolean
  }

  const notificationListColumns: Array<notificationListColumnstype> = [
    {
      id: 'typeElement',
      label: t('ecp-notifications-list.tableColumns.type', 'Type'),
      required: true
    },
    {
      id: 'messageElement',
      label: t('ecp-notifications-list.tableColumns.message', 'Message'),
      required: true
    },
    {
      id: 'statusElement',
      label: 'Status'
    },
    {
      id: 'updated',
      label: 'Updated',
      sortable: true
    },
    {
      id: 'modelName',
      label: 'Model Name'
    },
    {
      id: 'serialNumber',
      label: 'Serial Number'
    },
    {
      id: 'group',
      label: 'Group'
    },
    {
      id: 'deviceLocation',
      label: 'Device Location'
    },
    {
      id: 'id',
      label: 'ID',
      index: 'hidden'
    }
  ]

  const [notificationsResponse, setNotificationsResponse] = useState([])

  const createNotificationData = (notificationData: any[]) => {
    const messageLevelMap: any = {
      info: 'Information',
      success: 'Success',
      error: 'Error',
      warning: 'Warning'
    }

    const getTypeElement = (messageLevel: string) => {
      const getIcon = () => {
        if (messageLevel === 'info') {
          return (
            <IconInfo
              color="#ADADAD"
              data-testid={`notification-type-${messageLevel}-icon`}
              filled
              size={24}
            />
          )
        }
        if (messageLevel === 'success') {
          return (
            <IconCheckmarkCircle
              color="#1C7A17"
              data-testid={`notification-type-${messageLevel}-icon`}
              filled
              size={24}
            />
          )
        }
        if (messageLevel === 'error') {
          return (
            <IconXCircle
              color="#BE1313"
              data-testid={`notification-type-${messageLevel}-icon`}
              filled
              size={24}
            />
          )
        }
        if (messageLevel === 'warning') {
          return (
            <IconWarningAlt
              color="#D06702"
              data-testid={`notification-type-${messageLevel}-icon`}
              filled
              size={24}
            />
          )
        }
      }

      return (
        <TypeContainer data-testid={`notification-type-${messageLevel}`}>
          {getIcon()}
          <Gap4px />
          {messageLevelMap[messageLevel]}
        </TypeContainer>
      )
    }

    const getMessageLink = (notification: {
      link: string
      title: string
      acknowledgedAt: any
      id: string
    }) => {
      if (!notification.link) {
        return (
          <ColumnPiece>
            {notification.acknowledgedAt === null && (
              <ReadIcon
                data-testid={`notification-message-read-icon-${notification.id}`}
              />
            )}
            <Gap4px />
            <Tooltip
              arrow
              content={notification.title}
              contentHideDelayOnHover={0}
              contentShowDelayOnHover={0}
              data-testId={`notification-message-body-${notification.id}-tooltip`}
              id={`notification-message-body-${notification.id}-tooltip`}
              placement="bottom"
              portal
              useJsPositioning
            >
              <MessageBody
                data-testid={`notification-message-body-${notification.id}`}
              >
                {notification.title}
              </MessageBody>
            </Tooltip>
          </ColumnPiece>
        )
      }
      return (
        <ColumnPiece>
          {notification.acknowledgedAt === null && (
            <ReadIcon
              data-testid={`notification-message-read-icon-${notification.id}`}
            />
          )}

          <Gap4px />

          <Tooltip
            arrow
            content={notification.title}
            contentHideDelayOnHover={0}
            contentShowDelayOnHover={0}
            data-testId={`notification-message-body-${notification.id}-tooltip`}
            id={`notification-message-body-${notification.id}-tooltip`}
            placement="bottom"
            portal
            useJsPositioning
          >
            <a
              data-testid={`notification-message-href-${notification.id}`}
              href={notification.link}
            >
              <MessageBody
                data-testid={`notification-message-body-${notification.id}`}
              >
                {notification.title}
              </MessageBody>
            </a>
          </Tooltip>
        </ColumnPiece>
      )
    }

    const getStatusIconColor = (status: 'Active' | 'Pending' | 'Resolved') => {
      const colorState = {
        Active: 'hunterGreen7',
        Pending: 'red7',
        Resolved: 'highContrastGray'
      }

      return colorState[status]
    }

    const MessageStatus = (
      receivedAt: Date,
      messageLevel: string,
      id: string,
      dismissedAt: Date
    ) => {
      const status = getMessageStatus(receivedAt, messageLevel, dismissedAt)
      const statuses = ['Active', 'Pending', 'Resolved']
      if (status !== 'N/A' && statuses.includes(status)) {
        return (
          <StyledStatus>
            <IconCircle
              data-testid={`notification-status-circle-${id}`}
              color={getStatusIconColor(
                status as 'Active' | 'Pending' | 'Resolved'
              )}
              filled
              size={12}
            />
            {status}
          </StyledStatus>
        )
      } else {
        return 'N/A'
      }
    }

    const getReadStatus = (acknowledgedAt?: number) => {
      if (acknowledgedAt) {
        return 'read'
      } else {
        return 'unread'
      }
    }
    return notificationData.map((notification) => ({
      ...notification,
      typeElement: getTypeElement(notification.messageLevel),
      type: messageLevelMap[notification.messageLevel],
      messageElement: getMessageLink(notification) || '-',
      message: notification.title || '-',
      statusElement: MessageStatus(
        notification.receivedAt,
        notification.messageLevel,
        notification.id,
        notification.dismissedAt
      ),
      status:
        getMessageStatus(
          notification.receivedAt,
          notification.messageLevel,
          notification.dismissedAt
        ) || '-',
      updated: getUpdatedAt(notification.receivedAt, notification.deletedAt),
      modelName: notification.fields.deviceModel || '-',
      serialNumber: notification.fields.serialNumber || '-',
      group: notification.fields.group || '-',
      deviceLocation: notification.fields.device_location || '-',
      read: getReadStatus(notification.acknowledgedAt),
      id: notification.id
    }))
  }

  const [currentPage, setCurrentPage] = useState<number>(1)
  const [notificationData, setNotificationData] = useState([])
  const [notificationPageData, setNotificationPageData] = useState([])
  const [order, setOrder] = useState(
    jsonParse(
      sessionStorage.getItem(notificationsTableColumns),
      defaultOrderColumn
    )
  )
  const [totalItems, setTotalItems] = useState<number>(notifications.length)
  const [pageSize, setPageSize] = useState<number>(25)
  const [orderBy, setOrderBy] = useState(defSorting)
  const selectedItems = notificationData.filter(
    (data) => data['rowConfig'].selected
  )

  const numberOfSelectedItems: number = selectedItems.length

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const handlePageSizeChange = (
    event: UIEvent<HTMLElement>,
    option: PageSizeInterface
  ) => {
    event.preventDefault()
    setPageSize(option.value)
  }

  useEffect(() => {
    const filterData = filter(
      notificationData,
      searchNotificationItem,
      searchNotificationItems
    )
    setTotalItems(filterData.length)
    const currentStartIndex = (currentPage - 1) * pageSize
    const startIndex =
      filterData.length > currentStartIndex ? currentStartIndex : 0
    const pageData = filterData.slice(startIndex, startIndex + pageSize)
    setNotificationPageData(pageData)
    setTimeout(() => {
      handlePageChange(filterData.length > pageSize ? currentPage : 1)
    }, 100)
  }, [
    notifications,
    isLoading,
    searchNotificationItem,
    pageSize,
    searchNotificationItems,
    currentPage,
    notificationData
  ])

  const setPaginationData = useCallback(() => {
    const startIndex = (currentPage - 1) * pageSize

    const pageData = filter(
      notificationData,
      searchNotificationItem,
      searchNotificationItems
    ).slice(startIndex, startIndex + pageSize)

    setNotificationPageData(pageData)
  }, [
    currentPage,
    notificationData,
    pageSize,
    searchNotificationItem,
    searchNotificationItems
  ])

  const sortableNames: { [key: string]: string } = useMemo(() => {
    return {
      typeElement: 'type',
      messageElement: 'message',
      statusElement: 'status',
      updated: 'updated',
      modelName: 'modelName',
      serialNumber: 'serialNumber',
      group: 'group',
      deviceLocation: 'deviceLocation'
    }
  }, [])

  useEffect(() => {
    setNotificationsResponse(
      notifications.map((notification: any) =>
        Object.assign(notification, { rowConfig: { selectd: true } })
      )
    )
  }, [notifications])

  useEffect(() => {
    const newId = sortableNames[orderBy.id] || orderBy.id

    const getnotificationData = createNotificationData(
      notificationsResponse
    ).sort((object1, object2) =>
      sortTable(object1, object2, newId, orderBy.type === 'descending' ? 1 : -1)
    )

    setNotificationData(getnotificationData)
  }, [notificationsResponse, isLoading, orderBy, sortableNames])

  useEffect(() => {
    const newId2 = sortableNames[orderBy.id] || orderBy.id

    setNotificationPageData(
      notificationData.sort((object1, object2) =>
        sortTable(
          object1,
          object2,
          newId2,
          orderBy.type === 'descending' ? 1 : -1
        )
      )
    )

    setNotificationPageData(notificationData)

    setPaginationData()
  }, [notificationData, isLoading, orderBy, sortableNames, setPaginationData])

  useEffect(() => {
    setPaginationData()
  }, [currentPage, pageSize, setPaginationData])

  const handleSelect = (
    event: { target: { checked: boolean } },
    index: number | string
  ) => {
    setNotificationPageData(
      selectRow(notificationsResponse, event.target['checked'], index as number)
    )
    setPaginationData()
  }

  const handleSelectAllOrDeselectAll = (selected: boolean) => {
    handleMultipleNotifications(notificationData, selected)
    setPaginationData()
  }

  const handleSelectAllPageItems = (event: {
    target: { checked: boolean }
  }) => {
    setNotificationPageData(
      handleMultipleNotifications(notificationPageData, event.target['checked'])
    )
  }

  const handleOnColumnReorder = (newOrder: any) => {
    setOrder(newOrder)
    sessionStorage.setItem(notificationsTableColumns, JSON.stringify(newOrder))
  }

  const handleMarkRead = (notificationPageData: any[]) => {
    const toTimestamp = (date: Date) => Math.floor(date.getTime() / 1000)

    return notificationPageData.map((notification) => {
      if (!notification.rowConfig.selected) {
        return notification
      }
      if (notification.acknowledgedAt !== null) {
        return notification
      }
      return {
        ...notification,
        acknowledgedAt: toTimestamp(new Date())
      }
    })
  }

  const handleRemove = (notificationPageData: any[]) => {
    return notificationPageData.filter(
      (notification) => !notification.rowConfig.selected
    )
  }

  const handleSort = useCallback(
    (
      _: React.MouseEvent<HTMLElement, MouseEvent>,
      { id, type }: TableSortBy
    ) => {
      const regSorting = {
        id: id,
        type: type
      }
      setOrderBy(regSorting)
    },
    []
  )

  const TableData = hasError ? [] : notificationPageData

  return (
    <>
      <Table
        actionArea={actionArea}
        data-testid="notifications-data-table"
        className="notification-table-br"
        columns={notificationListColumns}
        columnReorder
        data={TableData}
        onSort={handleSort}
        preferences={{
          ...preferences,
          headerNoWrap: true,
          defaultOrder: defaultOrderColumn,
          order,
          sortBy: orderBy
        }}
        onColumnReorder={handleOnColumnReorder}
        loading={isLoading}
        loadingDataLength={5}
        pagination={{
          currentPage: currentPage,
          onPageChange: handlePageChange,
          onPageSizeChange: handlePageSizeChange,
          pageSize: pageSize,
          pageSizeOptions: defaultpageSizeOptions,
          popoverPlacement: 'top-start',
          totalItems
        }}
        // rowSelector={'multiSelection'}
        onSelect={handleSelect}
        onSelectAllPageItems={handleSelectAllPageItems}
        rowSelectAllState={selectionState(notificationPageData)}
        component={'table'}
      />

      {numberOfSelectedItems > 0 && (
        <ContextualFooter
          data-testid="notifications-list-contextual-footer"
          numberOfSelectedItems={numberOfSelectedItems}
          onCancel={() => handleSelectAllOrDeselectAll(false)}
          onMarkRead={() =>
            setNotificationsResponse(handleMarkRead(notificationsResponse))
          }
          onRemove={() =>
            setNotificationsResponse(handleRemove(notificationsResponse))
          }
        />
      )}
    </>
  )
}

export default NotificationsData
