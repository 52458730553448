import styled from 'styled-components'
import Tokens from '@veneer/tokens'

export const NotificationContextualmenu = styled.div`
  @-webkit-keyframes animatebottom {
    from {
      bottom: -50px;
      opacity: 0;
    }
    to {
      bottom: 0px;
      opacity: 1;
    }
  }

  @keyframes animatebottom {
    from {
      bottom: -50px;
      opacity: 0;
    }
    to {
      bottom: 0;
      opacity: 1;
    }
  }

  animation: 1s ease 0s 1 normal none running animatebottom;
  bottom: 0;
  display: block;
  margin: 0;
  position: fixed;
  z-index: 3;
  width: 100%;

  @media screen and (max-width: ${Tokens.layout.lgMin}) {
    left: 0;
    transition: left 500ms ease 0s;
  }
`

export const NotificationFooterContent = styled.div`
  -webkit-box-align: center;
  -webkit-box-pack: justify;
  align-items: center;
  border-color: transparent;
  border-radius: 0;
  border-style: solid;
  border-width: 0 1px;
  box-sizing: border-box;
  background: #ebf9ff;
  display: flex;
  justify-content: space-between;
  padding: ${Tokens.layout.size3} ${Tokens.layout.size6};
  position: relative;

  label[data-focused='false'] {
    line-height: 16px !important;
  }

  label {
    line-height: 9px;
  }

  label + div > div[role='combobox'] {
    padding-left: 10px !important;
  }

  @media screen and (max-width: ${Tokens.layout.smMin}) {
    flex-direction: column-reverse;
    gap: $space-3;
  }

  @media screen and (min-width: ${Tokens.layout.lgMin}) {
    margin-left: -22px;
  }
`

export const LeadingAreaText = styled.span`
  font-family: ${Tokens.typography.family0};
  font-size: ${Tokens.typography.size2};
  font-weight: 400;
  line-height: ${Tokens.typography.lineHeight3};
  text-align: left;
  color: ${Tokens.color.gray9};
  width: 130px;
`

export const NotificationLeadingContent = styled.div`
  align-items: center;
  display: flex;
  gap: 9px;
  min-width: min-content;

  width: 236px;
  height: 36px;

  @media screen and (max-width: ${Tokens.layout.smMin}) {
    flex-direction: column;
    width: 100%;

    > * {
      width: 100%;
    }
  }
`

export const NotificationTrailingContent = styled.div`
  align-items: center;
  right: 30px;
  position: fixed;
  display: flex;
  justify-content: space-between;
  gap: ${Tokens.layout.size3};
  min-width: min-content;

  width: 346px;
  height: 36px;

  @media screen and (max-width: ${Tokens.layout.smMin}) {
    flex-direction: column;
    width: 100%;

    > * {
      width: 100%;
    }
  }
`
