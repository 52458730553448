/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import { Button, IconSearch, Search } from '@veneer/core'
import { SearchNotificationProps } from './type'
import { useShellRootContext } from '../../contexts/ShellRoot'

const SearchNotifications = ({
  isLoading,
  searchNotificationFlag,
  showMobileSearchBox,
  showSearchButton,
  setSearchNotificationFlag,
  setsearchNotificationItem,
  setSearchNotificationValue,
  setShowMobileSearchBox
}: SearchNotificationProps): any => {
  /* istanbul ignore next */
  const { t = (key: string, defaultValue: string) => defaultValue || key } =
    useShellRootContext?.() || {}

  const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState(window.innerWidth)
    const callback = () => setWindowSize(window.innerWidth)

    useEffect(() => {
      window.addEventListener('resize', callback)
      return () => window.removeEventListener('resize', callback)
    }, [])

    return windowSize
  }

  const resolution = useWindowSize()
  const isMobileView = resolution <= 600

  const getBackground = () => {
    return showMobileSearchBox ? '#0076AD1A' : 'transparent'
  }

  const searchButton = (
    <Button
      appearance="ghost"
      customStyle={{ background: getBackground() }}
      data-testid="notifications-mobile-screen-search-button"
      disabled={isLoading}
      leadingIcon={<IconSearch />}
      onClick={() => setShowMobileSearchBox(!showMobileSearchBox)}
    />
  )

  const notificationsListSearch = (
    <Search
      content={undefined}
      data-testid="notifications-search-box"
      disabled={isLoading}
      nonce={undefined}
      onChange={(value) => setSearchNotificationValue(value)}
      onClear={() => {
        setsearchNotificationItem('')
        setSearchNotificationFlag(!searchNotificationFlag)
      }}
      placeholder={t('ecp-notifications-list.search', 'Search Notifications')}
      rel={undefined}
      rev={undefined}
    />
  )

  return (
    <>
      {isMobileView && showSearchButton && searchButton}
      {!isMobileView && showSearchButton && notificationsListSearch}
      {isMobileView &&
        showMobileSearchBox &&
        !showSearchButton &&
        notificationsListSearch}
    </>
  )
}

export default SearchNotifications
