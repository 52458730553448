/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from 'react'
import { DirectionProvider, ThemeProvider, ToastProvider } from '@veneer/core'
import NotificationsPage from 'src/components/NotificationsPage'
import resources from 'src/assets/locale'
import { TranslatorFunctionType } from 'src/types/shell'
import { ShellRootProvider } from 'src/contexts/ShellRoot'
import * as S from './styles'
import * as T from './types'

// This file is your main component, it's the one that will be rendered by default.
// We recommend to create small components inside the components folder
// and import them here based on your need to make this file easier to understand.
const AppComponent: React.FC<T.AppPropsType> = () => {
  return (
    <S.Container>
      <NotificationsPage />
    </S.Container>
  )
}

// This component wraps your main component with the DependencyManagerProvider
// This provider is responsible to manage global dependencies to your component
const App: React.FC<T.AppPropsType> = ({
  authProvider,
  properties,
  ...rest
}) => {
  const [t, setT] = useState<TranslatorFunctionType>()

  const v1 = useMemo(() => {
    return rest as any
  }, [rest])

  const v2 = useMemo(() => {
    return { authProvider: authProvider } as any
  }, [authProvider])

  const interfaces = useMemo(() => {
    return {
      v1,
      v2
    }
  }, [v1, v2])

  const directionValue = v1?.localization?.useReactGetLanguageDirection?.(React)

  const { themeProviderProps, userThemeMode } = useMemo(() => {
    return {
      themeProviderProps: v1?.theme?.getThemeProviderProperties?.(),
      userThemeMode: v1?.theme?.getUserThemeMode?.()
    }
  }, [v1?.theme])

  useEffect(() => {
    v1.localization
      .createTranslatorFunction(resources)
      .then((v: any) => setT(() => v))
  }, [v1.localization])

  return (
    <DirectionProvider direction={directionValue}>
      <ThemeProvider
        {...themeProviderProps}
        mode={userThemeMode}
      >
        <ShellRootProvider {...{ interfaces, t, properties }}>
          <ToastProvider>
            <AppComponent />
          </ToastProvider>
        </ShellRootProvider>
      </ThemeProvider>
    </DirectionProvider>
  )
}

export default App
